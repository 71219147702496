/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/14/2021
 * @Example
 */

import React, {useEffect, useRef, useState} from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {FormGroup, Col, Row} from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import LabelText from "../../../common/label-text/label-text";
import {Input} from "@material-ui/core";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Filter from "../../../filter/filter";
import {Dropdown, Button, DatePicker, MoneyInput,
    ButtonIcon, Typography, Avatar } from "diginet-core-ui/components";
import {makeStyles} from "diginet-core-ui/theme";

const useStyles = makeStyles(theme => ({
    txtShiftName: {
        marginTop: 35,
        whiteSpace: "pre",
        [theme.breakpoints.down("md")]: {
            marginTop: 0
        }
    },
    customLabelText: {
        [theme.breakpoints.down("md")]: {
            marginBottom: 20
        }
    },
    customRequire: {
        color : theme.colors.warning
    }
}));

const W29F2082 = (props) => {
    const {getCboAppStatus} = props;
    const [iPermission, setIPermission] = useState(0);
    const [dataForm, setDataForm] = useState({});
    const filterCboEmployees = useRef({skip: 0, limit: 20, strSearch: ""});
    const [loading, setLoading] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [voucherID, setVoucherID] = useState("");
    const [dataFilter, setDataFilter] = useState({
        DateFrom: null,
        DateTo: null,
        EmployeeID: "",
        ApprovalStatus: ""
    });
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [employee, setDateEmployee] = useState(null);
    const [appStatusID, setAppStatusID] = useState(null);
    const classes = useStyles();

    const loadCboEmployees = (isReset) => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W29F2082",
            Language: Config.language || '84',
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch
        };
        setLoading(true);
        props.generalActions.getCboEmployees(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows || data;
                const total = data?.total || data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total
                });
            }
        });
    };

    const loadFormInfo = (VoucherID) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W29F2082",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        setLoading(true);
        props.approvalActions.getFormInfo(params, (error, data) => {
            setLoading(false);
            if (error) return Config.popup.show("ERROR", error);
            if (data) {
                setDataForm(data);
            }
        });
    };

    const loadCboStatus = () => {
        const params = {
            FormID: "W29F2035",
            Language: Config.language || '84'
        };
        setLoading(true);
        props.generalActions.getCboAppStatus(params, (error) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        });
    };

    useEffect(() => {
        const loadPermission = () => {
             props.generalActions.getPermission("W29F2082", (isPer) => {
                setIPermission(isPer);
            });
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(iPermission > 0) {
            loadCboEmployees();
            loadCboStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const onDrawerClosed = () => {
        setVoucherID("");
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        loadFormInfo(e?.VoucherID || "");
    };

    const handleChange = (key, e) => {
        if(!key || !e) return false;
        const value = e?.value ?? e?.target?.value ?? "";
          switch (key) {
              case "DateFrom":
                  setDateFrom(value || null);
                  break;
              case "DateTo":
                  setDateTo(value || null);
                  break;
              case "Employee":
                  setDateEmployee(value);
                break;
              case "AppStatusID":
                  setAppStatusID(value || null);
                break;
              default:
                  break;
          }
    };

    const onSearch = () => {
        setDataFilter({
            ...dataFilter,
            ApprovalStatus: appStatusID,
            DateFrom: dateFrom,
            DateTo: dateTo,
            EmployeeID: employee
        })
    };

    const renderFormInfo = (data) => {
        const user = Config.getUser({ EmployeeID: data?.EmployeeID || ""});
        const userPictureUrl = user?.UserPictureURL || "";
        const userName = user?.EmployeeName || "";

        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={5}>
                            <LabelText
                                className={classes.customLabelText}
                                fullWidth={true}
                                label={Config.lang("Nhan_vien")}
                                allowPadding={true}
                                value={() => (
                                    <div className={"display_row align-center"} style={{width: "100%", height: 37}}>
                                        <div >
                                            {userPictureUrl ? (
                                                <Avatar
                                                    className={"mgr10"}
                                                    readOnly={true}
                                                    width={32}
                                                    height={32}
                                                    src={
                                                        userPictureUrl.indexOf("http") < 0
                                                            ? Config.getCDNPath() + userPictureUrl
                                                            : userPictureUrl
                                                    }
                                                />
                                            ) : (
                                                <Avatar
                                                    readOnly={true}
                                                    width={32}
                                                    height={32}
                                                    src={require("../../../../assets/images/general/user_default.svg")}
                                                />
                                            )}
                                        </div>
                                        <Input color={"primary"} className={"mgl5"} readOnly={true} value={userName ? userName : ""} disableUnderline={true} />
                                    </div>
                                )}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <DatePicker
                                actionIconAt={"end"}
                                clearAble
                                displayFormat={'DD/MM/YYYY'}
                                label={Config.lang("Ngay")}
                                required={true}
                                readOnly={true}
                                value={data.AttendanceDate}
                                onChange={e => handleChange("DateFrom", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <Typography className={classes.txtShiftName}>{Config.helpers.getObjectValue(data, "ShiftName", "")}</Typography>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <MoneyInput
                                label={<>{Config.lang("Di_tre")} <span className={classes.customRequire}>*</span> </>}
                                value={String(data?.LateMinute ?? "")}
                                endIcon={
                                    <ButtonIcon viewType="text"
                                                size={"tiny"}
                                                name={'Close'}
                                                style={{padding: 0}}/>}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <MoneyInput
                                label={<>{Config.lang("Ve_som")} <span className={classes.customRequire}>*</span></>}
                                value={String(data?.EarlyMinute ?? "")}
                                endIcon={
                                    <ButtonIcon viewType="text"
                                                size={"tiny"}
                                                name={'Close'}
                                                style={{padding: 0}}/>}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <MoneyInput
                                label={<>{Config.lang("Vao_tre")} <span className={classes.customRequire}>*</span></>}
                                value={String(data?.InLateMinutes ?? "")}
                                endIcon={
                                    <ButtonIcon viewType="text"
                                                size={"tiny"}
                                                name={'Close'}
                                                style={{padding: 0}}/>}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <MoneyInput
                                label={<>{Config.lang("Ra_som")} <span className={classes.customRequire}>*</span></>}
                                value={String(data?.OutEarlyMinutes ?? "")}
                                endIcon={
                                    <ButtonIcon viewType="text"
                                                size={"tiny"}
                                                name={'Close'}
                                                style={{padding: 0}}/>}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("Ly_do")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "Reason","")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("Ghi_chu")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "Note","")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        )
    };

    const renderFilter = () => {
        return (
            <Filter
                isUseDDCore={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <div className={"display_row align-center"}>
                                        <Col xs={12} sm={3}> <Typography type={"h4"}>{Config.lang("Ngay_lam_viec")}</Typography></Col>
                                        <Col xs={12} sm={4}>
                                            <div className={"display_row align-center"}>
                                                <DatePicker
                                                    max={dateTo}
                                                    actionIconAt={"start"}
                                                    clearAble
                                                    disabled={loading}
                                                    displayFormat={'DD/MM/YYYY'}
                                                    viewType={"outlined"}
                                                    placeholder={Config.lang("Tu_ngay")}
                                                    value={dateFrom}
                                                    onChange={e => handleChange("DateFrom", e)}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            <div className={"display_row align-center"}>
                                                <DatePicker
                                                    min={dateFrom}
                                                    actionIconAt={"start"}
                                                    clearAble
                                                    disabled={loading}
                                                    displayFormat={'DD/MM/YYYY'}
                                                    viewType={"outlined"}
                                                    placeholder={Config.lang("Den_ngay")}
                                                    value={dateTo}
                                                    onChange={e => handleChange("DateTo", e)}
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total ?? 0}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            value={employee}
                                            loading={loading}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            searchDelayTime={1000}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={(e) => handleChange("Employee", e)}
                                            onInput={(e) => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            displayExpr={"AppStatusName"}
                                            label={Config.lang("Trang_thai")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("AppStatusID", e)}
                                            dataSource={getCboAppStatus}
                                            loading={loading}
                                            value={appStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button
                                    color={"primary"}
                                    viewType={'outlined'}
                                    startIcon={'Search'}
                                    text={Config.lang('Tim_kiem')}
                                    onClick={onSearch}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    if (!iPermission) return null;
    return (
        <>
            <FormGroup>
                <ActionToolbar
                    title={Config.lang("Duyet_di_tre_ve_som")}
                />
            </FormGroup>
            <FormGroup>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Approvals
                        parentProps={props}
                        FormID={"W29F2082"}
                        dataForm={dataForm}
                        dataFilter={dataFilter}
                        selectedRowKey={voucherID}
                        singleClick
                        formInfoRender={renderFormInfo}
                        filterRender={renderFilter}
                        onSelectionChanged={onSelectionChanged}
                        onDrawerClosed={onDrawerClosed}
                        disableEscapeKeyDown={false}
                    />
                </Col>
            </Row>
        </FormGroup>
        </>
    )

};

export default compose(connect((state) => ({
        getCboAppStatus: state.general.getCboAppStatus,
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
))(W29F2082);
