/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/17/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import {FormGroup, Row, Col}                       from "react-bootstrap";
import ActionToolbar                               from "../../../common/toolbar/action-toolbar";
import {connect}                                   from "react-redux";
import {bindActionCreators}                        from "redux";
import Config                                      from "../../../../config";
import * as generalActions                         from "../../../../redux/general/general_actions";
import Filter                                      from "../../../filter/filter";
import * as W29F2080Actions from "../../../../redux/W2X/W29F2080/W29F2080_actions.js";
import GridContainer        from "../../../grid-container/grid-container";
import {Column}             from "devextreme-react/data-grid";
import GridActionBar        from "../../../grid-container/grid-actionbar";
import {
    Avatar,
    Button,
    ButtonIcon,
    DateRangePicker,
    Dropdown,
    MoneyInput,
    Typography
} from 'diginet-core-ui/components';
import Status from "../../../common/status/status";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W29F2081 from "../W29F2081/W29F2081";

const W29F2080 = (props) => {
    const _initDataSource = {
        total: 0,
        rows: []
    };

    const _initFilter = {
        skip: 0,
        limit: 20
    };

    const dataFilterGrid = {
        DateFrom: null,
        DateTo: null,
        Employee: "",
        ShiftID: "",
        ShiftName: "",
        AppStatusID: null,
        LateMinute: null,
        LateMinuteOP: "",
        EarlyMinute: null,
        EarlyMinuteOP: "",
        InLateMinutes: "",
        InLateMinutesOP: "",
        OutEarlyMinutes: "",
        OutEarlyMinutesOP: "",
    };

    const _w84F3005PopupData = {
        TransID: null
    };

    const _dataPopup = {
        mode: "",
        TransID: "",
        AppStatusID: false,
    };

    const dataDropdownOperator = [
        {id: 1, name: Config.lang("Bang1"), operator: "="},
        {id: 2, name: Config.lang("Lon_hon_hoac_bang"), operator: ">="},
        {id: 3, name: Config.lang("Nho_hon_hoac_bang1"), operator: "<="},
    ];
    const [dataGrid,setDataGrid] = useState(_initDataSource);
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [iPermission,setPermission] = useState(0);
    const [dataPopup, setDataPopup] = useState(_dataPopup);
    const [dataFilter, setDataFilter] = useState(dataFilterGrid);
    const [dataCboAppStatus, setDataCboAppStatus] = useState([]);
    const [dataCboShift, setDataCboShift] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [rangeDate, setRangeDate] = useState([]);
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [showW29F2081Popup, setShowW29F2081Popup] = useState(false);
    const filterCboEmployees = useRef({skip: 0, limit: 20, strSearch: ""});
    const filterGrid = useRef(_initFilter);
    const refGridContainer = useRef(null);
    const w84F3005PopupData = useRef(_w84F3005PopupData);

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W29F2080",
            HostID: "",
            Language: Config.language || "84",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch
        };
        setLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
           setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows || data;
                const total = data?.total  || data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                })
            }
        });
    };

    const loadCboAppStatus = () => {
        const params = {
            Language: Config.language || 84
        };
        setLoading(true);
        props.w29F2080Actions.getCboAppStatus(params, (errors, data) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            setDataCboAppStatus(data || []);
        });
    };

    const loadCboShift = () => {
        setLoading(true);
        props.w29F2080Actions.getCboShifts((errors, data) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            setDataCboShift(data || []);
        });
    };

    const getOperator = (data) => {
        let text = dataDropdownOperator.find(item => item.id === data);
        return  text?.operator || "";
    };

    const loadGrid = () => {
        const params = {
            FormID : "W29F2080",
            Language: Config.language || 84,
            DateFrom: dataFilter?.DateFrom || null,
            DateTo: dataFilter?.DateTo || null,
            AppStatusID:  String(dataFilter?.AppStatusID ?? ""),
            ShiftID: dataFilter?.ShiftID || "",
            LateMinute: dataFilter?.LateMinute || 0,
            LateMinuteOP: getOperator(dataFilter?.LateMinuteOP) ?? "",
            EarlyMinute: dataFilter?.EarlyMinute || 0,
            EarlyMinuteOP: getOperator(dataFilter?.EarlyMinuteOP) || "",
            InLateMinutes: dataFilter?.InLateMinutes || 0,
            InLateMinutesOP: getOperator(dataFilter?.InLateMinutesOP) || "",
            OutEarlyMinutes: dataFilter?.OutEarlyMinutes || 0,
            OutEarlyMinutesOP: getOperator(dataFilter?.OutEarlyMinutesOP) || "",
            EmployeeID: dataFilter?.Employee,
            skip: filterGrid.current.skip,
            limit: filterGrid.current.limit,
        };
        setLoadingGrid(true);
        props.w29F2080Actions.getDataGrid(params, (errors, data) => {
            setLoadingGrid(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data) {
                const rows = data?.rows || data;
                const total = data?.total || data.length;
                setDataGrid({
                    rows,
                    total
                })
            }
        });
    };

    useEffect(() => {
        const loadPermission = async () => {
            await props.generalActions.getPermission("W29F2080", (isPer) => {
                setPermission(isPer);
            })
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(iPermission) {
            loadGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[iPermission]);

    useEffect(() => {
            Config.callChildForm({
                FormID: "W29F2080",
                keyExpr: "TransID",
                data: dataGrid.rows || [],
                onAction: (e, data) => {
                    if (data && data.TransID) {
                        setDataPopup({
                            mode: "view",
                            TransID: data?.TransID || "",
                            AppStatusID: data?.AppStatusID !== 0
                        });
                        setShowW29F2081Popup(true)
                    }
                }
            }, props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const setData = (obj) => {
        setDataFilter({...dataFilter,...obj})
    };

    const onHistoryApproval = (e, data = {}) => {
        w84F3005PopupData.current.TransID = data.TransID;
        setShowW84F3005Popup(true);
    };

    const onLoadDataFilter = () => {
        loadCboEmployees();
        loadCboAppStatus();
        loadCboShift();
    };

    const handleChange = (key,e) => {
        if(!key ) return false;
        const value = e?.value ?? e?.target?.value ?? "";
        switch (key) {
            case "RangePicker":
                const dateFrom = Array.isArray(value) ? value[0] : value;
                const dateTo = Array.isArray(value) ? value[1] : value;
                setData({
                    DateFrom: dateFrom,
                    DateTo: dateTo
                });
                setRangeDate([dateFrom, dateTo]);
                break;
            case "Employee":
                setData({Employee: value});
                break;
            case "ShiftID":
                setData({ShiftID: value});
                break;
            case "AppStatusID":
                setData({AppStatusID: value});
                break;
            case "LateMinute":
                setData({LateMinute: value});
                break;
            case "LateMinuteOP":
                setData({LateMinuteOP: value});
                break;
            case "EarlyMinute":
                setData({EarlyMinute: value});
                break;
            case "EarlyMinuteOP":
                setData({EarlyMinuteOP: value});
                break;
            case "InLateMinutes":
                setData({InLateMinutes: value});
                break;
            case "InLateMinutesOP":
                setData({InLateMinutesOP: value});
                break;
            case "OutEarlyMinutes":
                setData({OutEarlyMinutes: value});
                break;
            case "OutEarlyMinutesOP":
                setData({OutEarlyMinutesOP: value});
                break;
            default:
                break;
        }
    };

    const onAdd = () => {
        setDataPopup({
            mode: "add"
        });
        setShowW29F2081Popup(true)
    };

    const onEdit = (e) => {
        if(!e) return false;
        const {data} = e;
        setDataPopup({
            mode: "edit",
            TransID: data?.TransID || "",
            AppStatusID: data?.AppStatusID !== 0
        });
        setShowW29F2081Popup(true)
    };

    const onView = (e) => {
        if(!e) return false;
        const {data} = e.row;
        setDataPopup({
            mode: "view",
            TransID: data?.TransID || "",
            AppStatusID: data?.AppStatusID !== 0
        });
        setShowW29F2081Popup(true)
    };

    const onDelete = (e) => {
        if(!e) return false;
        const {data} = e.row;
        const params = {
            TransID: data?.TransID || ""
        };
        const dataSource = refGridContainer.current?.instance.option("dataSource");
        const row = dataSource.find(item => item.TransID === data?.TransID);
        const indx = refGridContainer.current?.instance.getRowIndexByKey(row?.TransID);
        Config.popup.show('YES_NO',Config.lang("Ban_co_chac_chan_xoa"),
            () =>{
                props.w29F2080Actions.deleteRow(params, (errors, data) => {
                    if (errors) {
                        Config.popup.show('ERROR', errors);
                        return false;
                    }
                    if (data.Status === 0) {
                        Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                        refGridContainer.current.instance.deleteRow(indx);
                    }
                })
            });
    };

    const onCloseModal = (isReload) => {
        if(isReload) {
            loadGrid();
        }
        setShowW29F2081Popup(false);
    };

    const onChangePage = (page) => {
        filterGrid.current.skip = page * filterGrid.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        filterGrid.current.skip = 0;
        filterGrid.current.limit = perPage;
        loadGrid();
    };

    const renderButtonAction = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return (
            <GridActionBar>
                <div className="">
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"history"}
                        onClick={() => onHistoryApproval(e, data)}
                    />
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"view"}
                        disabled={data?.AppStatusID !== 0}
                        onClick={() => onEdit(e)}
                    />
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"delete"}
                        disabled={data?.AppStatusID !== 0}
                        onClick={() => onDelete(e)}
                    />
                </div>
            </GridActionBar>
        );
    };

    const renderFilters = () => {
        return (
            <Filter
                isUseDDCore={true}
                openOnFieldClick={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <DateRangePicker
                                            clearAble
                                            controls
                                            value={rangeDate}
                                            viewType={"outlined"}
                                            label={Config.lang("Ngay_lam_viec")}
                                            onChange={e => handleChange("RangePicker", e)}
                                            placeholder={"DD/MM/YY - DD/MM/YY"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            searchDelayTime={1000}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={(e) => handleChange("Employee", e)}
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total || 0}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            value={dataFilter.Employee}
                                            loading={loading}
                                            onInput={(e) => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"ShiftID"}
                                            displayExpr={"ShiftName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Ca_lam_viec")}
                                            onChange={e => handleChange("ShiftID", e)}
                                            dataSource={dataCboShift}
                                            loading={loading}
                                            value={dataFilter.ShiftID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            displayExpr={"AppStatusName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Trang_thai")}
                                            onChange={e => handleChange("AppStatusID", e)}
                                            dataSource={dataCboAppStatus}
                                            loading={loading}
                                            value={dataFilter.AppStatusID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <MoneyInput
                                            viewType={'outlined'}
                                            label={Config.lang("Di_tre")}
                                            onChange={(e) => handleChange('LateMinute', e)}
                                            value={dataFilter.LateMinute}
                                            allowZero
                                            disabledNegative
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataDropdownOperator}
                                            displayExpr={"name"}
                                            valueExpr={"id"}
                                            viewType={"outlined"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Toan_tu")}
                                            onChange={e => handleChange("LateMinuteOP", e)}
                                            value={dataFilter.LateMinuteOP}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} md={6}>
                                        <MoneyInput
                                            viewType={'outlined'}
                                            label={Config.lang("Ve_som")}
                                            onChange={(e) => handleChange('EarlyMinute', e)}
                                            value={dataFilter.EarlyMinute}
                                            allowZero
                                            disabledNegative
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataDropdownOperator}
                                            displayExpr={"name"}
                                            valueExpr={"id"}
                                            viewType={"outlined"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Toan_tu")}
                                            value={dataFilter.EarlyMinuteOP}
                                            onChange={e => handleChange("EarlyMinuteOP", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <MoneyInput
                                            viewType={'outlined'}
                                            label={Config.lang("Vao_tre")}
                                            onChange={(e) => handleChange('InLateMinutes', e)}
                                            value={dataFilter.InLateMinutes}
                                            allowZero
                                            disabledNegative
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataDropdownOperator}
                                            displayExpr={"name"}
                                            valueExpr={"id"}
                                            viewType={"outlined"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Toan_tu")}
                                            value={dataFilter.InLateMinutesOP}
                                            onChange={e => handleChange("InLateMinutesOP", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <MoneyInput
                                            viewType={'outlined'}
                                            label={Config.lang("Ra_som")}
                                            onChange={(e) => handleChange('OutEarlyMinutes', e)}
                                            value={dataFilter.OutEarlyMinutes}
                                            allowZero
                                            disabledNegative
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataDropdownOperator}
                                            displayExpr={"name"}
                                            valueExpr={"id"}
                                            viewType={"outlined"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Toan_tu")}
                                            value={dataFilter.OutEarlyMinutesOP}
                                            onChange={e => handleChange("OutEarlyMinutesOP", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button
                                    startIcon={"search"}
                                    viewType={'outlined'}
                                    text={Config.lang('Tim_kiem')}
                                    onClick={loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        )
    };
    const renderStatus = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return <Status data={data} />;
    };

    const cellRenderEmployee = (e) => {
        if(!e) return null;
        const {data} = e;
        const user = Config.getUser({EmployeeID: data?.EmployeeID ?? ""});
        const userPictureURL = user?.UserPictureURL || "";
        const info = {
            [Config.lang('Nhan_vien')]: `${user?.EmployeeID ?? ""} - ${user?.EmployeeName ?? ""}`,
            [Config.lang('Phong_ban')]: user?.DepartmentName ?? "",
            [Config.lang('Du_an')]: user?.ProjectName ?? "",
            [Config.lang('Chuc_vu')]: user?.DutyName ?? "",
            [Config.lang('Ngay_vao_lam')]: Config.convertDate(user?.DateJoined ?? "", '', 'L') ?? "",
        };
        return (
            <div className={"display_row align-center"}>
                {(userPictureURL) ?
                    (
                        <Avatar
                        width={34}
                        height={34}
                        data={info}
                        src={userPictureURL.indexOf("http") < 0
                            ? Config.getCDNPath() + userPictureURL
                            : userPictureURL}
                        readOnly
                        hoverAble
                        />
                    )
                    : (
                        <Avatar
                        width={34}
                        height={34}
                        data={info}
                        hoverAble
                        readOnly
                        src={require("../../../../assets/images/general/user_default.svg")}
                        />
                    )}
                <Typography type={"p1"} className={"mgl15"}>{user?.EmployeeName || ""}</Typography>
            </div>
        )
    };

    if(!iPermission) return false;
    return (
        <FormGroup>
            <ActionToolbar title={Config.lang("Dang_ky_di_tre_ve_som")}>
                <Button
                    color={"primary"}
                    viewType={"filled"}
                    startIcon={"AddCircle"}
                    style={{textTransform: "uppercase"}}
                    text={Config.lang('Them')}
                    onClick={onAdd}
                />
            </ActionToolbar>
            <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                      FormID="W29F2080" TransID={w84F3005PopupData.current.TransID}/>
            {showW29F2081Popup ?
                <W29F2081
                    data={{
                        mode: dataPopup.mode,
                        TransID: dataPopup.TransID,
                        AppStatusID: dataPopup.AppStatusID
                    }}
                    open={showW29F2081Popup}
                    onClose={(isReload) => onCloseModal(isReload)} />
                : ""}

            <div className={"hidden"}>{renderFilters()}</div>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            reference={ref => refGridContainer.current = ref}
                          // use deleteRow Grid Container don't show popup confirm:
                            editing={{
                                texts: {
                                    confirmDeleteMessage: "",
                                },
                            }}
                            style={{ border: "none" }}
                            keyExpr={"TransID"}
                            dataSource={dataGrid.rows || []}
                            totalItems={dataGrid.total || 0}
                            showRowLines={true}
                            showBorders={false}
                            showColumnLines={false}
                            noDataText={Config.lang("No_data")}
                            onDbCellClick={onView}
                            loading={loadingGrid}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                            itemPerPage={filterGrid.current.limit}
                            skipPerPage={filterGrid.current.skip}
                            typePaging={"remote"}
                            onChangePage={onChangePage}
                            onChangePerPage={onChangePerPage}
                        >
                            {Config.isMobile && (
                                <Column
                                    width={80}
                                    alignment={"right"}
                                    allowEditing={false}
                                    cellRender={renderButtonAction}
                                />
                            )}
                            <Column
                                width={250}
                                caption={Config.lang("Nhan_vien")}
                                cellRender={cellRenderEmployee}
                                alignment={"left"}
                            />
                            <Column
                                caption={Config.lang("Ngay_lam_viec")}
                                dataField={"AttendanceDate"}
                                alignment={"left"}
                            />
                            <Column
                                caption={Config.lang("Ca_lam_viec")}
                                dataField={"ShiftName"}
                                alignment={"left"}
                            />
                            <Column
                                width={100}
                                caption={Config.lang("Di_tre")}
                                dataField={"LateMinute"}
                                alignment={"center"}
                            />
                            <Column
                                width={100}
                                caption={Config.lang("Ve_som")}
                                dataField={"EarlyMinute"}
                                alignment={"center"} />
                            <Column
                                width={100}
                                caption={Config.lang("Vao_tre")}
                                dataField={"InLateMinutes"}
                                alignment={"center"} />
                            <Column
                                width={100}
                                caption={Config.lang("Ra_som")}
                                dataField={"OutEarlyMinutes"}
                                alignment={"center"} />
                            <Column
                                caption={Config.lang("Ly_do")}
                                width={100}
                                dataField={"Reason"}
                                alignment={"center"} />
                            <Column
                                cellRender={renderStatus}
                                alignment={"center"}
                                width={200} />
                            <Column
                                fixed={true}
                                fixedPosition={"right"}
                                visible={!Config.isMobile}
                                cellRender={renderButtonAction}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </FormGroup>
        </FormGroup>
    )
};

export default connect(null,
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w29F2080Actions: bindActionCreators(W29F2080Actions, dispatch),
    }))(W29F2080);
