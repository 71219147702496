/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 4/29/2021
 * @Example
 */

import React, { useEffect, useRef, useState } from "react";

import Config from "../../../../config";
import { useDispatch } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W29F2070Actions from "../../../../redux/W2X/W29F2070/W29F2070_actions";
import { Button, Divider, Status } from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import { Col, Row } from "react-bootstrap";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import moment from "moment";
import W29F2072Popup from "./W29F2072Popup";
import History from "../../../libs/history";

const styles = {
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#000000"
    },
    styleDate: {
        fontWeight: 700,
    },
};

const W29F2070 = props => {
    const [formID] = useState("W29F2070");
    const [iPermission, setIPermission] = useState(0);

    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [loading, setLoading] = useState(false);
    const [showW29F2072Popup, setShowW29F2072Popup] = useState(false);
    const [dataPopup, setDataPopup] = useState({
        mode: "",
        rowData: null,
    });
    const [IsExport, setIsExport] = useState(0);

    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");
    const filter = useRef({
        DateFrom: null,
        DateTo: null,
        skip: 0,
        limit: 10,
        SearchValue: "",
    });

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission("W29F2070", isPer => {
                setIPermission(isPer);
            })
        );
    };
    const loadIsExport = () => {
        dispatch(
            W29F2070Actions.getIsExport((error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else {
                    setIsExport(data?.IsExport || 0);
                }
            })
        );
    };

    const onAdd = () => {
        setDataPopup({
            mode: "add",
        });
        setShowW29F2072Popup(true);
    };

    const loadData = () => {
        let params = {
            DivisionID: Config.getDivisionID() || "",
            UserID: Config.profile.UserID || "",
            HostID: "",
            FormID: formID,
            Language: Language.current,
            skip: filter.current.skip,
            limit: filter.current.limit,
            SearchValue: filter.current.SearchValue,
        };
        if (filter.current.DateFrom) params.DateFrom = filter.current.DateFrom;
        if (filter.current.DateTo) params.DateTo = filter.current.DateTo;
        setLoading(true);
        dispatch(
            W29F2070Actions.loadGrid(params, (errors, data) => {
                setLoading(false);
                if (errors) {
                    const message = errors.message || Config.lang("HRM_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    const rows = data && data.rows ? data.rows : data;
                    const total = data && data.total ? data.total : data.length;
                    setDataGrid({
                        rows: rows,
                        total: total,
                    });
                }
                setLoading(false);
            })
        );
    };

    useEffect(() => {
        loadPermission();
        loadIsExport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);
    const onChangePage = page => {
        filter.current.skip = page * filter.current.limit;
        loadData();
    };

    const onChangePerPage = perPage => {
        filter.current.skip = 0;
        filter.current.limit = perPage;
        loadData();
    };

    const saveHistory = async data => {
        if (!data) return false;
        const TransID = data?.TransID || "";
        if (TransID) {
            // data Content
            const _data = {
                AttendanceDate: data?.AttendanceDate || "",
                ShiftName: data?.ShiftName || "",
                ShiftID: data?.ShiftID || "",
                OTHour: data?.OTHour || "",
                ReasonOT: data?.ReasonOT || "",
                Note: data?.Note || "",
                OTTypeID: data?.OTTypeID || "",
                IsConfirm01: Number(data?.IsConfirm01 || 0),
                IsConfirm02: Number(data?.IsConfirm02 || 0),
                IsConfirm03: Number(data?.IsConfirm03 || 0),
                IsConfirm04: Number(data?.IsConfirm04 || 0),
                IsConfirm05: Number(data?.IsConfirm05 || 0),
            };

            //name Content
            const captions = {
                AttendanceDate: "ngay_cong",
                ShiftName: "ca_vao_ra",
                ShiftID: "data_ShiftID",
                OTHour: "gio_tang_ca",
                ReasonOT: "ly_do_tang_ca",
                Note: "ghi_chu",
                OTTypeID: "data_OTTypeID",
                IsConfirm01: "data_IsConfirm01",
                IsConfirm02: "data_IsConfirm02",
                IsConfirm03: "data_IsConfirm03",
                IsConfirm04: "data_IsConfirm04",
                IsConfirm05: "data_IsConfirm05",
            };
            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D29",
                TransID: TransID,
                TransactionID: "W29F2070",
                TransactionName: "Lập lịch tăng ca hàng loạt",
            };
            const history = new History(options); //Init history
            if (await history.save()) {
            } else {
                Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };
    const filterChange = (key, e) => {
        switch (key) {
            case "DateFrom":
                filter.current[key] = e?.value || "";
                break;
            case "DateTo":
                filter.current[key] = e?.value || "";
                break;
            case "SearchValue":
                filter.current[key] = e?.target.value || "";
                break;
            default:
                break;
        }
    };

    const onSearch = e => {
        filterChange("SearchValue", e);
        let timer;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            loadData();
        }, 500);
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                // onTextChanged={e => filterChange("SearchValue", e)}
                onTextChanged={e => onSearch(e)}
                renderFilter={() => {
                    return (
                        <>
                            <div style={{ marginBottom: "7px" }}>
                                <Row style={{ padding: 15 }}>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DateBoxPicker
                                            label={Config.lang("Tu")}
                                            shrink={true}
                                            dateBoxProps={{ max: filter?.current.DateFrom || null }}
                                            onValueChanged={e => filterChange("DateFrom", e)}
                                            showClearButton={true}
                                            placeholder={"dd/mm/yyyy"}
                                            value={filter?.current.DateFrom || ""}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DateBoxPicker
                                            label={Config.lang("Den")}
                                            shrink={true}
                                            dateBoxProps={{ min: filter?.current.DateTo || null }}
                                            onValueChanged={e => filterChange("DateTo", e)}
                                            showClearButton={true}
                                            placeholder={"dd/mm/yyyy"}
                                            value={filter?.current.DateTo || ""}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className={"display_row align-center valign-middle"}>
                                <Button text={Config.lang("Tim_kiem")} viewType="outlined" onClick={loadData} startIcon={"Search"} />
                            </div>
                        </>
                    );
                }}
            />
        );
    };
    const onView = e => {
        const { data } = e.row;
        setDataPopup({
            mode: "view",
            rowData: data,
        });
        setShowW29F2072Popup(true);
    };

    const onCloseModal = (isReload = false) => {
        if (isReload) {
            loadData();
        }
        setShowW29F2072Popup(false);
    };
    const renderDate = e => {
        if (!e) return null;
        const { data } = e || {};
        const date = (data.AttendanceDate && moment.utc(data.AttendanceDate).format("DD/MM/YYYY")) || null;
        return (
            <div className={"pdt10 pdb10 "} style={{ ...styles.divText }}>
                {data.AttendanceDate && (
                    <div style={{ ...styles.divDateText }}>
                        <span style={{ ...styles.styleDate, color: Config.color ? Config.color["MainColor"] : "#111D5E" }}>{Config.lang("Ngay")}: </span>
                        <span>{date || ""} </span>
                        <span style={{ ...styles.styleDate, color: Config.color ? Config.color["MainColor"] : "#111D5E" }}>{Config.lang("Ma_phieu")}: </span>
                        <span>{data?.VoucherCode || ""} </span>
                    </div>
                )}
                {data.Description && <div style={{ ...styles.divDateText }}>{data.Description}</div>}
            </div>
        );
    };
    const renderInfo = e => {
        if (!e) return null;
        const { data } = e || {};
        return (
            <div className={"pdt10 pdb10"} style={{ ...styles.divText }}>
                {data.ReasonOT && (
                    <div style={{ ...styles.divDateText }}>
                        <span style={{ ...styles.styleDate, color: Config.color ? Config.color["MainColor"] : "#111D5E" }}>
                            {Config.lang("Ly_do_tang_ca")}:{" "}
                        </span>
                        <span>{data.ReasonOT || ""}</span>
                    </div>
                )}
                {data.Note && (
                    <div style={{ ...styles.divDateText }}>
                        <span style={{ ...styles.styleDate, color: Config.color ? Config.color["MainColor"] : "#111D5E" }}>{Config.lang("Ghi_chu")}: </span>
                        <span>{data.Note || ""}</span>
                    </div>
                )}
            </div>
        );
    };
    const onEdit = e => {
        const { data } = e.row;
        setDataPopup({
            mode: "edit",
            rowData: data,
        });
        setShowW29F2072Popup(true);
    };
    const onDelete = data => {
        const param = {
            FormID: "W29F2070",
            TransID: data.TransID,
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            dispatch(
                W29F2070Actions.deleteRow(param, async error => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else {
                        await saveHistory(data);
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                        loadData();
                    }
                })
            );
        });
    };
    const onExport = data => {
        const param = {
            TransID: data.TransID,
        };
        dispatch(
            W29F2070Actions.exportData(param, async (error, dataFile) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (dataFile) {
                    const link = document.createElement("a");
                    link.href = dataFile.URL ? dataFile.URL : "";
                    link.download = dataFile.fileName ? dataFile.fileName : "";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
        );
    };
    const renderAction = e => {
        if (!e) return null;
        const { data } = e || {};
        return (
            <div className={"display_row align-center"}>
                <Icon disabled={iPermission <= 0} name={"View"} onClick={() => onEdit(e)} />
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: "0 5px" }} />
                <Icon disabled={iPermission <= 3 || !!data.IsDelete} name={"Trash"} onClick={() => onDelete(data)} />
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: "0 5px" }} />
                <Icon disabled={!IsExport} name={"Paper"} onClick={() => onExport(data)} />
            </div>
        );
    };

    const renderStatus = ({ data }) => {
        const status = data?.HRApprovalStatus ?? 0;
        const color = Number(status) === 1 ? 'success' : Number(status) === 2 ? 'danger' : 'secondary';
        const text = data?.HRStatusName ?? Config.lang('Khong_trang_thai');
        return <Status color={color} text={text} />;
    };

    return (
        <>
            <ActionToolbar title={Config.lang("Lap_lich_tang_ca_hang_loat")}>
                <Button
                    color={"primary"}
                    viewType={"filled"}
                    text={Config.lang("Them").toUpperCase()}
                    startIcon={"AddCircle"}
                    onClick={onAdd}
                    disabled={iPermission <= 1}
                />
            </ActionToolbar>
            <div className={"hidden"}>{renderFilter()}</div>
            <GridContainer
                style={{ border: "none" }}
                dataSource={dataGrid?.rows || []}
                totalItems={dataGrid?.total || 0}
                itemPerPage={filter.current.limit}
                skipPerPage={filter.current.skip}
                onDbCellClick={onView}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                noDataText={Config.lang("No_data")}
                hoverStateEnabled={true}
                loading={loading}
                typePaging={"remote"}
                height={Config.getHeightGrid() - 30}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderDate} />
                <Column cellRender={renderInfo} />
                <Column cellRender={renderStatus} minWidth={100} />
                <Column cellRender={renderAction} width={200} />
            </GridContainer>
            {showW29F2072Popup && (
                <W29F2072Popup
                    data={{
                        modeW29F2070: dataPopup.mode,
                        rowData: dataPopup.rowData,
                    }}
                    iPermission={iPermission}
                    open={showW29F2072Popup}
                    onClose={isReload => onCloseModal(isReload)}
                />
            )}
        </>
    );
};

export default W29F2070;
