/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/17/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import { compose, bindActionCreators }           from "redux";
import { connect }                               from "react-redux";
import { Col, Row, FormGroup }    from "react-bootstrap";
import { makeStyles }             from "diginet-core-ui/theme";
import Config                     from "../../../../config";
import {MForm}                    from "../../../common/form-material";
import CDN                        from "../../../CDN";
import * as W29F2081Actions       from "../../../../redux/W2X/W29F2081/W29F2081_actions";
import * as generalActions        from "../../../../redux/general/general_actions";
import {
    TextInput,
    Dropdown,
    MoneyInput,
    ButtonIcon,
    Modal,
    ModalHeader,
    ModalBody, Typography, ModalFooter, Button, DatePicker, Label, Attachment
} from 'diginet-core-ui/components';
import moment from "moment";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    customRequire: {
        color: theme.colors.warning,
        marginRight: 90
    },
    customLabel: {
        width: "100%",
        maxWidth: "inherit"
    },
    txtShiftName: {
        marginTop: 35,
        whiteSpace: "pre",
        [theme.breakpoints.down("md")]: {
            marginTop: 0
        }
    },
}));

const W29F2081 = (props) => {
    const classes = useStyles();

    const _initDataForm = {
        Employee : "",
        AttendanceDate : "",
        LateMinute: "",
        LateTimeIn: "",
        EarlyMinute : "",
        EarLyTimeOut: "",
        InLateMinutes: "",
        InLateTimeIn: "",
        OutEarlyMinutes: "",
        OutEarlyTimeOut: "",
        ShiftID: "",
        ShiftName: "",
        Reason: "",
        Note: "",
    };

    const {data, open, onClose} = props;
    const { mode, TransID, AppStatusID } = data || {};
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [errorHelperText, _setErrorHelperText] = useState({});
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModeView, setIsModeView] = useState(false);
    const [dataForm, setDataForm] = useState(_initDataForm);
    const filterCboEmployees = useRef({skip: 0, limit: 20, strSearch: ""});
    const time = useRef({lateTimeIn: "", earLyTimeOut: "",inLateTimeIn: "", outEarlyTimeOut: ""});
    const isChanged = useRef(false);
    const isModeEdit = useRef(false);
    const isChangeAttendanceDate = useRef(false);
    const attachments = useRef([]);
    const deletedFile = useRef([]);

    const loadCboEmployees = (isReset) => {
        const params = {
            Type: "EmployeeID",
            FormID: "W29F2080",
            HostID: "",
            Language: Config.language || "84",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch
        };
        setLoading(true);
        props.generalActions.getCboEmployees(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows || data;
                const total = data?.total  || data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                })
            }
        });
    };

    const loadForm = () => {
        const params = {
            FormID: "W29F2081",
            Language: Config.language || 84,
            Mode: 1,
            TransID: TransID
        };
        setLoading(true);
        props.w29F2081Actions.getDataForm(params, (errors, data) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            isModeEdit.current = true;
            if(data) {
                const getUser = Config.getUser({EmployeeID: data?.[0]?.EmployeeID ?? ""});
                setDataCboEmployees({rows: getUser ? [getUser] : [], total: getUser ? [getUser].length : 0});
                setData({
                    Employee: getUser?.EmployeeID || "",
                    ShiftID: data?.[0]?.ShiftID ?? "",
                    ShiftName: data?.[0]?.ShiftName ?? "",
                    AttendanceDate: data?.[0]?.AttendanceDate ?? "",
                    LateMinute: data?.[0]?.LateMinute ?? "",
                    EarlyMinute: data?.[0]?.EarlyMinute ?? "",
                    InLateMinutes: data?.[0]?.InLateMinutes ?? "",
                    OutEarlyMinutes: data?.[0]?.OutEarlyMinutes ?? "",
                    Reason: data?.[0]?.Reason ?? "",
                    Note: data?.[0]?.Note ?? "",
                });
            }
        })
    };

    const loadShift = () => {
       const params = {
           AttendanceDate: dataForm?.AttendanceDate || "",
           OTFrom: "",
           OTTo: "",
           EmployeeID: dataForm?.Employee ?? "",
           ShiftID: "",
           Mode: 4,
           Language: Config.language || 84
       };
        setLoading(true);
        props.w29F2081Actions.getShift(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data?.[0]?.Status === 1) {
                let message = data?.[0]?.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else {
                setDataShift(data);
            }
        });
    };

    const setDataShift = (data = []) => {
        const lateTimeIn =  setTime(data?.[0]?.LateTimeIn, "add", dataForm?.LateMinute);
        const earLyTimeOut =  setTime(data?.[0]?.EarLyTimeOut,"subtract", dataForm?.EarlyMinute);
        const inLateTimeIn =  setTime(data?.[0]?.InLateTimeIn,"add", dataForm?.InLateMinutes);
        const outEarlyTimeOut =  setTime(data?.[0]?.OutEarlyTimeOut,"subtract",dataForm?.OutEarlyMinutes);
        setData({
            ShiftID: data?.[0]?.ShiftID ?? "",
            ShiftName: data?.[0]?.ShiftName ?? "",
            LateTimeIn: (isChangeAttendanceDate || isModeEdit.current) ? lateTimeIn : data?.[0]?.LateTimeIn,
            EarLyTimeOut: (isChangeAttendanceDate || isModeEdit.current) ? earLyTimeOut : data?.[0]?.EarLyTimeOut,
            InLateTimeIn: (isChangeAttendanceDate || isModeEdit.current) ? inLateTimeIn : data?.[0]?.InLateTimeIn,
            OutEarlyTimeOut: (isChangeAttendanceDate || isModeEdit.current) ? outEarlyTimeOut : data?.[0]?.OutEarlyTimeOut,
        });
        time.current.lateTimeIn = data?.[0]?.LateTimeIn ?? "";
        time.current.earLyTimeOut = data?.[0]?.EarLyTimeOut ?? "";
        time.current.inLateTimeIn = data?.[0]?.InLateTimeIn ?? "";
        time.current.outEarlyTimeOut = data?.[0]?.OutEarlyTimeOut ?? "";
    };

    const loadAttachments = () => {
        const param = {
            KeyID: TransID || "",
            TableName: "D75T4071"
        };
        setLoading(true);
        props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            setLoading(false);
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    useEffect(() => {
        if(mode === "add") {
            const getUser = Config.getUser({EmployeeID: Config.getHREmployeeID()});
            setDataCboEmployees({rows: getUser ? [getUser] : [], total: getUser ? [getUser].length : 0});
            setData({Employee : getUser?.EmployeeID || ""});
        }
        if(mode === "edit") {
            loadForm();
            loadAttachments();
        }
        if(mode === "view") {
            setIsModeView(true);
            loadForm();
            loadAttachments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.mode]);

    useEffect(() => {
        if(isModeEdit.current && dataForm?.LateTimeIn && dataForm?.EarLyTimeOut
        && dataForm?.InLateTimeIn && dataForm?.OutEarlyTimeOut
        ) {
            isModeEdit.current = false;
            isChangeAttendanceDate.current = false;
        }
    }, [dataForm]);

    useEffect(() => {
        if(dataForm?.AttendanceDate) {
            isChangeAttendanceDate.current = true;
            loadShift();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.AttendanceDate]);

    useEffect(() => {
        if(!isModeEdit.current) {
            if (dataForm?.LateMinute) {
                const lateTimeIn = setTime(time?.current?.lateTimeIn,"add",dataForm.LateMinute);
                setData({LateTimeIn: lateTimeIn});
            } else {
                if (isChanged.current) {
                    setData({LateTimeIn: time?.current?.lateTimeIn ?? ""});
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.LateMinute]);

    useEffect(() => {
        if(!isModeEdit.current) {
            if (dataForm?.EarlyMinute) {
                const earLyTimeOut = setTime(time?.current?.earLyTimeOut,"subtract", dataForm.EarlyMinute);
                setData({EarLyTimeOut: earLyTimeOut});
            } else {
                if (isChanged.current) {
                    setData({EarLyTimeOut: time?.current?.earLyTimeOut ?? ""});
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.EarlyMinute]);

    useEffect(() => {
        if(!isModeEdit.current) {
            if (dataForm?.InLateMinutes) {
                const inLateTimeIn = setTime(time?.current?.inLateTimeIn,"add", dataForm.InLateMinutes);
                setData({InLateTimeIn: inLateTimeIn});
            } else {
                if (isChanged.current) {
                    setData({InLateTimeIn: time?.current?.inLateTimeIn ?? ""});
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.InLateMinutes]);

    useEffect(() => {
        if(!isModeEdit.current) {
            if (dataForm?.OutEarlyMinutes) {
                const outEarlyTimeOut = setTime(time?.current?.outEarlyTimeOut,"subtract", dataForm.OutEarlyMinutes);
                setData({OutEarlyTimeOut: outEarlyTimeOut})
            } else {
                if (isChanged.current) {
                    setData({OutEarlyTimeOut: time?.current?.outEarlyTimeOut ?? ""});
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.OutEarlyMinutes]);

    const setTime = (time, nameOperator = "add", minute) => {
        if(!time) return false;
        return moment(time, "HH:mm").isValid() ?
            moment(time, "HH:mm")[nameOperator](minute, "minutes").format("HH:mm") : "";
    };

    const setStateErrorText = (objValue) => {
        if(objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setErrorHelperText = (obj) => {
        _setErrorHelperText({...errorHelperText, ...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [ ...e.removedAttached];
            const _arrRemove    = deletedFile.current.map((d) => d.AttachmentID);
            const dataNew       = [...dataOldAttachments].filter(att => _arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const _getAttachments = (file) => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _removeCDN = () => {
        if (deletedFile.current && deletedFile.current.length > 0) {
            deletedFile.current.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            deletedFile.current= [];
        }
    };

    const onClosePopup = (isReload) => {
        if(onClose) onClose(isReload);
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        isChanged.current = true;
        const value = e?.value ?? e?.target?.value ?? "";
        if (errorHelperText[key] && value) setErrorHelperText({[key]: ""});
        switch (key) {
            case "AttendanceDate":
                if(value === "") {
                    setData({
                        AttendanceDate: value,
                        ShiftName: "",
                        LateTimeIn: "",
                        EarLyTimeOut: "",
                        InLateTimeIn: "",
                        OutEarlyTimeOut: ""
                    });
                } else {
                    setData({AttendanceDate: value});
                }
                break;
            case "LateMinute":
                setData({LateMinute: value});
                break;
            case "EarlyMinute":
                setData({EarlyMinute: value});
                break;
            case "InLateMinutes":
                setData({InLateMinutes: value});
                break;
            case "OutEarlyMinutes":
                setData({OutEarlyMinutes: value});
                break;
            case "Reason":
                setData({Reason: value});
                break;
            case "Note":
                setData({Note: value});
                break;
            default:
                break;
        }
    };

    const checkTime = (time) => {
        return !moment(time, "HH:mm").isValid();
    };

    const onSave = async () => {
        const validateRules = [
            {
                name: "AttendanceDate",
                rules: ["isRequired"],
                value: dataForm.AttendanceDate || ""
            },
        ];

        if(dataForm?.LateMinute === "" &&
            dataForm?.EarlyMinute === "" &&
            dataForm?.InLateMinutes === "" &&
            dataForm?.OutEarlyMinutes === "") {
            Config.popup.show("INFO", Config.lang("Phai_nhap_so_phut_cho_it_nhat_mot_loai_xin_phep"));
            return;
        }

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if(setStateErrorText(validateForm)) return false;
        }
        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const arrAttachments = _getAttachments(dataResUploadFile);
        const employeeInfo = dataCboEmployees.rows?.find(item => item.EmployeeID === dataForm?.Employee);
        const  params = {
            Mode: (data.mode === "edit") ? 1 : 0,
            TransID: TransID ?? "",
            EmployeeID : dataForm?.Employee ?? "",
            EmployeeName: employeeInfo?.EmployeeName ?? "",
            AttendanceDate : dataForm?.AttendanceDate ?? "",
            LateMinute: dataForm?.LateMinute || null,
            LateTimeIn: dataForm?.LateTimeIn ?? "",
            EarlyMinute : dataForm?.EarlyMinute || null,
            EarlyTimeOut: dataForm?.EarLyTimeOut ?? "",
            InLateMinutes: dataForm?.InLateMinutes || null,
            InLateTimeIn: dataForm?.InLateTimeIn ?? "",
            OutEarlyMinutes: dataForm?.OutEarlyMinutes || null,
            OutEarlyTimeOut: dataForm?.OutEarlyTimeOut ?? "",
            ShiftID: dataForm?.ShiftID ?? "",
            Reason: dataForm?.Reason ?? "",
            Note: dataForm?.Note ?? "",
            arrAttachment: JSON.stringify(arrAttachments),
        };

        props.w29F2081Actions.save(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data.Status === 1) {
                if(!data.hasOwnProperty("MsgAsk")) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data?.MsgAsk === 0) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if(data?.MsgAsk === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
            }
            if (data.Status === 0) {
                _removeCDN();
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                onClosePopup(true);
            }
        })

    };
    const errorAttendanceDate = !!errorHelperText['AttendanceDate'] && errorHelperText['AttendanceDate'].length > 0;
    const checkLateTimeIn = checkTime(dataForm?.LateTimeIn);
    const checkEarLyTimeOut = checkTime(dataForm?.EarLyTimeOut);
    const checkInLateTimeIn = checkTime(dataForm?.InLateTimeIn);
    const checkOutEarlyTimeOut = checkTime(dataForm?.OutEarlyTimeOut);
    return (
        <>
            <Modal
                onClose={() => onClosePopup()}
                open={open}
                pressESCToClose={false}
                moveable={false}
                dragAnyWhere={false}
                moveOutScreen={false}
                width={"80%"}
            >
                <ModalHeader title={Config.lang("Dang_ky_di_tre_ve_som")} />
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={4}>
                                <Dropdown
                                    clearAble
                                    required
                                    readOnly
                                    dataSource={dataCboEmployees?.rows || []}
                                    total={dataCboEmployees?.total ?? 0}
                                    skip={filterCboEmployees.current.skip}
                                    limit={filterCboEmployees.current.limit}
                                    value={dataForm?.Employee}
                                    loading={loading}
                                    iconExpr={"UserPictureURL"}
                                    viewType={"underlined"}
                                    valueExpr={"EmployeeID"}
                                    keyExpr={"EmployeeName"}
                                    searchDelayTime={1000}
                                    label={Config.lang("Nhan_vien")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"{EmployeeID} - {EmployeeName}"}
                                    onChange={(e) => handleChange("Employee", e)}
                                    onInput={(e) => {
                                        filterCboEmployees.current.strSearch = e.target.value;
                                        filterCboEmployees.current.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={(e) => {
                                        filterCboEmployees.current.skip = e.skip;
                                        filterCboEmployees.current.limit = e.limit;
                                        loadCboEmployees();
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4}>
                                <DatePicker
                                    error={errorAttendanceDate ? errorHelperText['AttendanceDate'] : ""}
                                    label={Config.lang('Ngay')}
                                    required
                                    clearAble
                                    readOnly={isModeView}
                                    viewType={"underlined"}
                                    actionIconAt={'end'}
                                    placeholder={'DD/MM/YYYY'}
                                    displayFormat={'DD/MM/YYYY'}
                                    value={dataForm?.AttendanceDate}
                                    onChange={(e) => handleChange('AttendanceDate', e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={3}>
                                <Typography className={classes.txtShiftName} type={"p1"}>{dataForm?.ShiftName || ""}</Typography>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={3}>
                                <Label className={classes.customLabel}>
                                    <>
                                        {Config.lang("Di_tre")}
                                        <span className={classes.customRequire}>*</span>
                                        <span className={"pull-right"}>{dataForm?.LateTimeIn || ""}</span>
                                    </>
                                </Label>
                                <MoneyInput
                                    value={String(dataForm?.LateMinute ?? "")}
                                    decimalDigit={0}
                                    readOnly={isModeView || !dataForm?.AttendanceDate || checkLateTimeIn}
                                    onChange={(e) => handleChange("LateMinute", e)}
                                    endIcon={
                                        <ButtonIcon viewType="text"
                                                    size={"tiny"}
                                                    name={'Close'}
                                                    style={{padding: 0}}
                                                    onClick={() => {
                                                        setData({LateMinute: ""});
                                                    }}
                                        />}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={3}>
                                <Label className={classes.customLabel}>
                                    <>
                                        {Config.lang("Ve_som")}
                                        <span className={classes.customRequire}>*</span>
                                        <span className={"pull-right"}>{dataForm?.EarLyTimeOut || ""}</span>
                                    </>
                                </Label>
                                <MoneyInput
                                    value={String(dataForm?.EarlyMinute ?? "")}
                                    decimalDigit={0}
                                    readOnly={isModeView || !dataForm?.AttendanceDate || checkEarLyTimeOut}
                                    onChange={(e) => handleChange("EarlyMinute", e)}
                                    endIcon={
                                        <ButtonIcon viewType="text"
                                                    size={"tiny"}
                                                    name={'Close'}
                                                    style={{padding: 0}}
                                                    onClick={() => {
                                                        setData({EarlyMinute: ""});
                                                    }}
                                        />}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={3}>
                                <Label className={classes.customLabel}>
                                    <>
                                        {Config.lang("Vao_tre")}
                                        <span className={classes.customRequire}>*</span>
                                        <span className={"pull-right"}>{dataForm?.InLateTimeIn || ""}</span>
                                    </>
                                </Label>
                                <MoneyInput
                                    value={String(dataForm?.InLateMinutes ?? "")}
                                    decimalDigit={0}
                                    readOnly={isModeView || !dataForm?.AttendanceDate || checkInLateTimeIn}
                                    onChange={(e) => handleChange("InLateMinutes", e)}
                                    endIcon={
                                        <ButtonIcon viewType="text"
                                                    size={"tiny"}
                                                    name={'Close'}
                                                    style={{padding: 0}}
                                                    onClick={() => {
                                                        setData({InLateMinutes: ""});
                                                    }}
                                        />}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={3}>
                                <Label className={classes.customLabel}>
                                    <>
                                        {Config.lang("Ra_som")}
                                        <span className={classes.customRequire}>*</span>
                                        <span className={"pull-right"}>{dataForm?.OutEarlyTimeOut || ""}</span>
                                    </>
                                </Label>
                                <MoneyInput
                                    value={String(dataForm?.OutEarlyMinutes ?? "")}
                                    decimalDigit={0}
                                    readOnly={isModeView || !dataForm?.AttendanceDate || checkOutEarlyTimeOut}
                                    onChange={(e) => handleChange("OutEarlyMinutes", e)}
                                    endIcon={
                                        <ButtonIcon viewType="text"
                                                    size={"tiny"}
                                                    name={'Close'}
                                                    style={{padding: 0}}
                                                    onClick={() => {
                                                        setData({OutEarlyMinutes: ""});
                                                    }}
                                        />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextInput
                                    maxRows={3}
                                    multiline
                                    label={Config.lang("Ly_do")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    readOnly={isModeView}
                                    value={dataForm.Reason}
                                    disabled={loading}
                                    onChange={(e) => handleChange("Reason", e)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextInput
                                    maxRows={3}
                                    multiline
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    readOnly={isModeView}
                                    value={dataForm.Note}
                                    disabled={loading}
                                    onChange={(e) => handleChange("Note", e)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Attachment
                                    label={Config.lang("Dinh_kem")}
                                    data={dataOldAttachments}
                                    disabled={loading || (mode === 'edit' && AppStatusID) || isModeView}
                                    onChange={(e) => {
                                        onChangeAttachments(e)
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        text={Config.lang("Luu")}
                        color={"info"}
                        startIcon={"save"}
                        viewType={"filled"}
                        disabled={loading || isModeView}
                        size={"medium"}
                        onClick={onSave}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

W29F2081.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    onClose:PropTypes.func,
};

W29F2081.defaultProps = {
    data: {},
    open: false,
    onClose: null,
};

export default compose(
    connect(null,
        (dispatch) => ({
            w29F2081Actions: bindActionCreators(W29F2081Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W29F2081);
